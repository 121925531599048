import React from 'react';
import Recaptcha from 'react-grecaptcha';
import axios from 'axios';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';
import StringUtils from '../utils/StringUtils';
import Browser from '../utils/Browser';
import Overlay from './Overlay';

class VerificationSearch extends React.Component {
  constructor(props) {
    super(props);

    const { lang } = this.props;
    
    this.lang = lang;
    this.i18n = new I18n({lang});

    this.state = {
      box1EmailOrName: "",
      box1BtnEnabled: true,
      box2EmailOrName: "",
      box2BtnEnabled: true,
      overlays: {
        resultsOverlay: false,
        recaptchaOverlay: false
      },
      resultData: [],
      overlayData: null,
      errorMsg: '',
      loading: false,
      activeRecaptchaBox: null,
      pupper: {},
      error: null,
      searchedText: null,
      displayResults: false,
      resultErrorMessage: null
    };
  }

  // Called when change a field value
  handleFields = e => {
    this.setState({ [e.target.name]: e.target.value });
    e.preventDefault();
  };

  // Called when click on clear button (x)
  clearField = f => this.setState({ [f]: '' });

  displayLoading = () => {
    this.setState({displayResults: true, loading: true, resultData: []});
  };

  hideLoading = () => {
    this.setState({loading: false});
  };

  // After click on 1st box button
  getCertificationData = () => {
    this.clearField('box2EmailOrName');

    if (this.state.box1EmailOrName == '') {
      this.setState({
        displayResults: true,
        errorMsg: this.i18n.text('verification_message_fill_form')
      });

    } else {
      this.setState({
        errorMsg: '',
        box1BtnEnabled: false,
        box2BtnEnabled: false,
        activeRecaptchaBox: 1
      });

      this.displayRecaptchaOverlay();
    }
  };

  // After click on 2nd box button
  getStatusData = () => {
    this.clearField('box1EmailOrName');

    if (this.state.box2EmailOrName == '') {
      this.setState({
        displayResults: true,
        errorMsg: this.i18n.text('verification_message_fill_form')
      });
    
    } else if( !this.checkEmailFormat(this.state.box2EmailOrName) ) {
      this.setState({
        displayResults: true,
        errorMsg: this.i18n.text('verification_error_invalid_email')
      });

    } else {
      this.setState({
        errorMsg: '',
        box1BtnEnabled: false,
        box2BtnEnabled: false,
        activeRecaptchaBox: 2
      });

      this.displayRecaptchaOverlay();
    }
  };

  // When Recaptcha Success
  recaptchaSuccessAction = response => {
    this.displayLoading();
    this.overlayOut('recaptchaOverlay');
    this.queryCertificationData(response);
  };

  // When Recaptcha Expired
  expiredCallback = () => {
    if (window) {
      window.grecaptcha.reset();
    }
  };

  displayRecaptchaOverlay = () => {
    setTimeout(() => {
      this.overlayIn('recaptchaOverlay');
    }, 500);
  };

  queryCertificationData = async (recaptcha_key) => {

    this.setState({
      resultErrorMessage: ''
    });

    if (this.state.activeRecaptchaBox == 1) {
      const searchText = this.state.box1EmailOrName;
      const browserInfo = Browser.getInfo();

      const postData = {
        headers: {
          "captcha-response": recaptcha_key + "###;v2"
        },
        params: {
          searchString: searchText, 
          browserName: browserInfo.browser,
          browserVersion: browserInfo.version, 
          osName: browserInfo.OS, 
          osVersion: browserInfo.OsVersion,
          languageLocaleKey: this.lang
        }
      };
      
      const axiosConfig = {
        headers: {
          "captcha-response": recaptcha_key + "###;v2"
        }
      };

      await axios.get( process.env.GATSBY_DRM_ENDPOINT, postData).then( result => {
        let resultMessage;
        let data = [];
        
        if( result.data.status != "success" ) {
          switch(result.data.errorCode) {
            case 1: 
              resultMessage = this.i18n.text('verification_message_no_results', StringUtils.sanitizeHtml(searchText));
              break;
            case 2:
              resultMessage = this.i18n.text('verification_message_missing_fields');
              break;
            case 3:
              resultMessage = this.i18n.text('verification_message_missing_recaptcha');
              break;
            case 4:
              resultMessage = this.i18n.text('verification_message_invalid_recaptcha');
              break;
            default:
              resultMsg = this.i18n.text('verification_message_error_internal');
              break;
          }
        
        } else {
          const serverResponse = JSON.parse(result.data.data[0].jsonResponse);
          data = serverResponse.data;
        }

        this.setState({
          loading: false,
          resultErrorMessage: resultMessage,
          searchedText: searchText,
          resultData: data,
          box1BtnEnabled: true,
          box2BtnEnabled: true
        });

      }).catch( error => {
        this.setState({
          loading: false,
          searchedText: null,
          resultErrorMessage: resultMsg = this.i18n.text('verification_message_error_internal'),
          resultData: data,
          box1BtnEnabled: true,
          box2BtnEnabled: true
        });
      });
    
    } else if(this.state.activeRecaptchaBox == 2) {
      const browserInfo = Browser.getInfo();
      const searchText = this.state.box2EmailOrName;

      const postData = {
        searchString: this.state.box2EmailOrName,
        languageLocaleKey: this.lang
      };
      
      const axiosConfig = {
        headers: {
          "captcha-response": recaptcha_key,
          "browserName":browserInfo.browser,
          "browserVersion":browserInfo.version,
          "osName":browserInfo.OS,
          "osVersion":browserInfo.OsVersion
        }
      };

      let errorMsg = '';

      this.setState({
        resultErrorMessage: ''
      });
      
      // POST AJAX (SECOND BOX)
      await axios.post(process.env.GATSBY_DRM_ENDPOINT, postData, axiosConfig).then( result => {

        let resultMsg = '';

        if( result.data.status != 'success' ){

          switch(result.data.errorCode) {
            case 1: 
            case 3:
              resultMsg = this.i18n.text('verification_message_no_results', StringUtils.sanitizeHtml(searchText));
              break;
            case 2:
              resultMsg = this.i18n.text('verification_message_missing_fields');
              break;
            case 4:
              resultMsg = this.i18n.text('verification_message_missing_recaptcha');
              break;
            case 5:
              resultMsg = this.i18n.text('verification_message_invalid_recaptcha');
              break;
            default:
              resultMsg = this.i18n.text('verification_message_error_internal');
              break;
          }

        } else {
          resultMsg = this.i18n.text('verification_result_sent_by_email', [StringUtils.sanitizeHtml(searchText)]);
        }

        if(resultMsg != '') {
          this.setState({
            resultErrorMessage: resultMsg,
            loading: false
          });
        }
      })
    }
  }

  checkEmailFormat = (str) => {
    var re = /(.+)@(.+\.)+[a-zA-Z]{2,}$/;
    return re.test(String(str).toLowerCase());
  }

  overlayIn = (overlayId) => {
    if(overlayId == 'recaptchaOverlay') {
      if (window) {
        window.grecaptcha.reset();
      }
    }
    if(Object.hasOwnProperty.call(this.state.overlays, overlayId)) {
      this.setState( prevState => {
        const overlays = Object.assign({}, prevState.overlays); // creating copy of state variable overlays
        overlays[overlayId] = true; // update the display property, assign a new value                 
        return { overlays }; // return new overlay object
      })
    }
  };

  overlayOut = (overlayId) => {

    if(overlayId == 'recaptchaOverlay') {
      this.setState({
        box1BtnEnabled: true,
        box2BtnEnabled: true
      });
    }

    if(Object.hasOwnProperty.call(this.state.overlays, overlayId)) {
      this.setState( prevState => {
        const overlays = Object.assign({}, prevState.overlays);
        overlays[overlayId] = false;           
        return { overlays };
      });
    }

    if(overlayId == 'resultsOverlay') {
      setTimeout(() => {
        this.setState({
          overlayData: null
        });
      }, 1000, this);
    }
  };

  displayResultsOverlay = (name, rowId) => {
    this.setState({
      overlayData: this.state.resultData[rowId]
    });

    setTimeout(() => {
      this.overlayIn('resultsOverlay');
    }, 500, this);
  };

  render() {

    const {overlayData} = this.state;

    return (
      <>
        <div className="slds-is-relative">
          <div className="cs-page-verif_overlaped-section">
            <div className="slds-container--medium slds-container_center slds-p-horizontal_medium">
              <div className="slds-grid slds-wrap slds-grid--align-center slds-grid--pull-padded slds-grid--vertical-stretch">
                <div className="slds-size--1-of-1 slds-medium-size--1-of-2 slds-p-horizontal--medium slds-p-vertical--medium">
                  <div className="cs-page-verif_box">
                    <div className="slds-text-align--center Fz(24px) Fw(b)">
                      <h2>{this.i18n.text('verification_box1_title')}</h2>
                    </div>

                    <div className="cs-page-verif_field-container slds-m-top--medium">
                      <input
                        className="inputVerification"
                        type="text"
                        name="box1EmailOrName"
                        placeholder={this.i18n.text('verification_box1_placeholder')}
                        onChange={this.handleFields}
                        value={this.state.box1EmailOrName}
                        alt={this.i18n.text('verification_box1_placeholder')}
                        title={this.i18n.text('verification_box1_tooltip')}
                      />
                      <button className="clear-btn" alt="Clear Search button" onClick={() => this.clearField('box1EmailOrName')}></button>
                      <tds-tooltip style={{position: "absolute", top: "6px", right: "-26px"}}>
                        <tds-icon slot="trigger" style={{display: "inline-block"}} size="medium" symbol="info"></tds-icon>
                        <div slot="tooltip">{this.i18n.text('verification_box1_tooltip')}</div>
                      </tds-tooltip>
                    </div>

                    <div className="slds-text-align--center slds-p-top--medium">
                      <button className="cert-btn--orange D(ib) Tt(u)" onClick={() => this.getCertificationData()} disabled={!this.state.box1BtnEnabled}>
                        {this.i18n.text('verification_box1_button')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="slds-size--1-of-1 slds-medium-size--1-of-2 slds-p-horizontal--medium slds-p-vertical--medium">
                  <div className="cs-page-verif_box">
                    <div className="slds-text-align--center Fz(24px) Fw(b)">
                      <h2>{this.i18n.text('verification_box2_title')}</h2>
                    </div>

                    <div className="cs-page-verif_field-container slds-m-top--medium">
                      <input
                        className="inputVerification"
                        type="text"
                        name="box2EmailOrName"
                        placeholder={this.i18n.text('verification_box2_placeholder')}
                        onChange={this.handleFields}
                        value={this.state.box2EmailOrName}
                        alt={this.i18n.text('verification_box2_placeholder')}
                        title={this.i18n.text('verification_box2_tooltip')}
                      />

                      <button className="clear-btn" alt="Clear Search button" onClick={() => this.clearField('box2EmailOrName')}></button>
                    </div>

                    <div className="slds-text-align--center slds-p-top--medium">
                      <button 
                        className="cert-btn--orange D(ib) Tt(u)" 
                        onClick={() => this.getStatusData()} 
                        disabled={!this.state.box2BtnEnabled} >
                          {this.i18n.text('verification_box2_button')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <Overlay id="recaptchaOverlay" display={this.state.overlays.recaptchaOverlay} hideMethod={this.overlayOut}>
            <div style={{ padding: '50px 100px' }}>
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                {this.i18n.text('verification_complete_recaptcha')}
              </div>
              <Recaptcha
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                callback={this.recaptchaSuccessAction}
                expiredCallback={this.expiredCallback}
                className="customClassName"
                data-theme="light"
              />
            </div>
        </Overlay>

        <div className="slds-container--center slds-container--medium slds-p-top_large slds-p-horizontal_medium slds-is--relative">
          {this.state.displayResults && (
            <div className="cs-box-1 slds-m-top_xx-large">
              {this.state.loading && (
                <div className="cs-spinner-container">
                  <tds-spinner></tds-spinner>
                </div>
              )}

              {this.state.errorMsg != "" && (
                <div className="slds-text-align_center Fz(18)">{this.i18n.text('error')}: {this.state.errorMsg}</div>
              )}

              {this.state.resultErrorMessage != null && (
                <div className="Fz(20px) slds-m-vertical--medium">{this.state.resultErrorMessage}</div>
              )}

              {this.state.resultData.length > 0 && this.state.resultData.map((item, rowId) => (
                <div key={`result-item_${rowId}`}>
                  <div className="Fz(20px) slds-m-vertical--medium">{this.i18n.text('verification_message_results_for', this.state.searchedText)}</div>
                  <div className="slds-m-top--xx-large">
                    <div className="cs-exam-table__line clearfix">
                      <div className="D(ib)">
                        <div className="Fz(14px) C(blue-intense) Fw(b) slds-p-right--medium">{item.Name}</div>
                        <div className="slds-m-top--small C(gray-light2) Fz(14px)">
                          {item.City}<span className="slds-p-horizontal--small">&nbsp;</span>
                          {item.State}<span className="slds-p-horizontal--small">&nbsp;</span>
                          {item.Country}
                        </div>
                      </div>
                      <div className="F(r)">
                        {!Object.hasOwnProperty.call(item, 'RelatedCertificationStatus') && (
                          <div>{this.i18n.text('verification_no_credentials_info')}</div>
                        )}
                        {Object.hasOwnProperty.call(item, 'RelatedCertificationStatus') && (
                          <button
                            onClick={() => {this.displayResultsOverlay(item.Name, rowId, item.MappingKey)}} 
                            className="slds-button icon-after-show">
                              {this.i18n.text('verification_credentials_info')}
                          </button>
                        )}
                        
                      </div>
                    </div>
                  </div>
                </div>
              ))}


              {this.state.overlayData != null && (
                <Overlay id="resultsOverlay" display={this.state.overlays.resultsOverlay} hideMethod={this.overlayOut}>
                  <div>
                    <div className="Fz(20px) C(dark-gray) Fw(b) slds-text-align--center slds-p-vertical--x-large">{overlayData.Name}</div>
                    <div className="Bgc(gray-light) slds-p-horizontal--small">
                      <div className="slds-grid slds-p-top--x-large slds-p-bottom--large">
                        <div className="slds-size_1-of-2 slds-text-align_left slds-p-left_large">
                          {this.i18n.text('verification_title_credential')}
                          </div>
                        <div className="slds-size_1-of-2 slds-text-align_right slds-p-right_large">
                          {this.i18n.text('verification_title_earned_date')}
                        </div>
                      </div>
  
                      <hr style={{margin:'0'}} />
  
                      <div className="cs-page-verif_overlay-scroll">
  
                        { Object.hasOwnProperty.call(overlayData, 'RelatedCertificationStatus') && 
                          overlayData.RelatedCertificationStatus.records.map((item, i) => {
                            
                            const defaultImageUrl = 'https://developer.salesforce.com/resources2/certification-site/images/credentials/' + StringUtils.camelize(item.ExternalCertificationTypeName) + '.jpg';
                            const imageUrl = item.hasOwnProperty('RelatedCertificationType') && item.RelatedCertificationType.hasOwnProperty('Image') ? item.RelatedCertificationType.Image : defaultImageUrl;
  
                            return (
                              <div key={`certi-item-${i}`}>
                                <div className="slds-grid slds-p-vertical--medium slds-grid--vertical-align-center">
                                  <div className="slds-size_1-of-6" style={{width:'100px'}}>
                                    <img style={{width:'100%'}} src={imageUrl}/>
                                  </div>
                                  <div className="slds-col slds-p-horizontal_small">
                                    {item.ExternalCertificationTypeName}
                                  </div>
                                  <div className="slds-size_1-of-6 slds-text-align_right" style={{width: '140px'}}>
                                    {item.CertificationDate}
                                  </div>
                                </div>
                                <hr className="cs-page-verif_overlay-separator" />
                              </div>
                            )
                          })
                        }
                      </div>
                      <div 
                        className="slds-p-vertical--large slds-text-align--center" 
                        dangerouslySetInnerHTML={{__html: this.i18n.text('verification_title_footer_text')}}
                      >
                      </div>
                    </div>
                    <div className="slds-text-align--center slds-p-vertical--x-large">
                      <a 
                        className="slds-button slds-button_neutral slds-m-horizontal--small Fz(20) Lh(2x)" 
                        target="_blank" 
                        href={`/${this.lang}/credentials/certification-detail-print?searchString=${overlayData.MappingKey}`}>
                          {this.i18n.text('verification_footer_print_view')}
                      </a>
                      <a 
                        className="slds-button slds-button_brand slds-m-horizontal--small Fz(20) Lh(2x)" 
                        target="_blank" 
                        href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME">
                          {this.i18n.text('verification_footer_add_to_linkedin')}
                      </a>
                    </div>
                  </div>
                </Overlay>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

VerificationSearch.propTypes = {
  lang: PropTypes.string.isRequired
};

export default VerificationSearch;
