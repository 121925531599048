import React from 'react';
import PropTypes from 'prop-types';

class Overlay extends React.Component {
    constructor(props) {
        super(props);

        const { id, display, hideMethod, children } = this.props;

        this.hideMethod = hideMethod.bind(this);
        this.id = id;
        this.children = children;

        this.state = {
            display: display,
            startShowAnim: false
        };

        this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.display && !prevProps.display) {
            this.showOverlay();
        }
        if (!this.props.display && prevProps.display) {
            this.setState({
                startShowAnim: false
            });
    
            setTimeout(() => {
                this.setState({
                    display: false
                });
            }, 1000, this);
        }
    }

    showOverlay = () => {
        this.setState({
            display: true
        });
        setTimeout(() => {
            this.setState({
                startShowAnim: true
            });
        }, 100, this);
    };

    hideOverlay = () => {
        this.setState({
            startShowAnim: false
        });

        setTimeout(() => {
            this.hideMethod(this.id);
        }, 1000, this);
    };

    init = () => {
        //this.showOverlay();
    };

    render() {
        const {display, startShowAnim} = this.state;

        let classValue = display ? `D(f) ${startShowAnim ? 'in' : ''}` : 'D(n)';

        return (
            <div
                id={this.id}
                className={`cs-overlay slds-align--absolute-center ${classValue}`}
                onClick={() => { this.hideOverlay() }}>
                <div className="cs-overlay_container slds-container--center slds-container--medium">
                    <div className="cs-overlay_modal" onClick={(e) => e.stopPropagation()}>
                        {this.children}
                    </div>
                </div>
            </div>
        );
    };
}

Overlay.propTypes = {
    id: PropTypes.string.isRequired,
    display: PropTypes.bool.isRequired,
    hideMethod: PropTypes.func.isRequired,
    children: PropTypes.node
};
  
export default Overlay;
